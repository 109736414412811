import type { PageProps } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useMemo } from 'react'
import { RenderClient } from 'src/components/RenderClient'
import { useScrollEvent } from 'src/sdk/analytics/hooks/useScrollEvent'

type Props = PageProps

const useErrorState = (location: Location) =>
  useMemo(() => {
    const params = new URLSearchParams(location.search)
    const errorId = params.get('errorId')
    const fromUrl = params.get('from')

    return {
      errorId,
      fromUrl,
    }
  }, [location.search])

function Page({ location }: Props) {
  const { errorId, fromUrl } = useErrorState(location)

  useEffect(() => {
    window.addEventListener('scroll', useScrollEvent)
    return () => {
      window.removeEventListener('scroll', useScrollEvent)
    }
  }, [])

  return (
    <RenderClient>
      <GatsbySeo />

      <h1>500</h1>
      <h2>Internal Server Error</h2>

      <div>
        The server errored with id {errorId} when visiting page {fromUrl}
      </div>
    </RenderClient>
  )
}

export default Page
