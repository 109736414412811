import { sendAnalyticsEvent } from '@faststore/sdk/dist/analytics/sendAnalyticsEvent'
import { ScrollDepth } from '../types'

const desiredScrollRanges = [
  { min: 25, max: 49, printed: false },
  { min: 50, max: 74, printed: false },
  { min: 75, max: 89, printed: false },
  { min: 90, max: 100, printed: false },
]

export const useScrollEvent = () => {
  const getScrollPercent = function () {
    var winHeight = window.innerHeight
    var docHeight = document.documentElement.scrollHeight
    var scrollTop = window.scrollY || document.documentElement.scrollTop
    var trackLength = docHeight - winHeight
    var scrollPercent = Math.floor((scrollTop / trackLength) * 100)
    return scrollPercent
  }

  const percentage = getScrollPercent()

  const matchingRange = desiredScrollRanges.find(
    (range) => percentage >= range.min && percentage <= range.max
  )

  if (matchingRange && !matchingRange.printed) {
    sendAnalyticsEvent<ScrollDepth>({
      name: 'scroll_depth',
      params: {
        scroll_threshold: matchingRange.min,
      },
    })
    matchingRange.printed = true
  }
}
